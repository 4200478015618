@import "../../variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.homepage-stats-carousel-wrapper {
    background-color: $light-green-secondary;
    padding: 32px 0px;
    .homepage-stats-carousel-text {
        color: $dark-green;
        font-family: $font-qualion;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 26px;
        }
    }
    .homepage-stats-carousel-dot {
        border-radius: 50%;
        width: 5px;
        height: 5px;
        background-color: $dark-green;
    }
    .homepage-stats-carousel-slide {
        @include media-breakpoint-up(md) {
            width: fit-content;
        }
    }
}
